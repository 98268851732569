footer {
    background-color: @c-blue--main;
    color: #FFF;
    padding: 100px 0;
    position: relative;
    @media @mobile {
        padding: 30px 0;
    }
    p {
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.footer__row {
    @media @mobile {
        display: block;
    }
}


.footer__col {
    @media @desktop-min {
        border-right: 1px solid rgba(255,255,255,.2);
        padding: 0 30px;
        &:nth-of-type(3), &:nth-of-type(4) {
            border: 0;
        }
    }
    @media @desktop {
        margin: 15px 0;
        flex: 1;
        &:first-of-type {
            flex: 1 0 100%;
            border-bottom: 1px solid rgba(255,255,255,.2);
            padding-bottom: 30px;
        }
        &:last-of-type {
            flex: 1 0 100%;
            border-top: 1px solid rgba(255,255,255,.2);
            padding-top: 30px;
        }
        &:nth-of-type(2) {
            border-right: 1px solid rgba(255,255,255,.2);
        }
    }
    @media @mobile {
        padding-top: 30px;
        padding-bottom: 30px;
        margin: 0;
    }
}

.footer__link-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.footer__link-item {
    margin-bottom: 10px;
    &:last-of-type {
        margin-bottom: 0;
    }
}

.footer__link {
    color: #FFF;
   // text-transform: uppercase;
   text-decoration: none; 
   letter-spacing: 1px;
    &:hover {
        color: #FFF;
    }
}

.footer__title {
    font-weight: 400;
    margin-bottom: 20px;
    line-height: 1.3;
    &--small {
        font-size: .8rem;
        font-weight: 500;
        text-transform: uppercase;
    }
}

.last-updated {
    margin: 30px 0;
    display: block;
}

.last-updated__text {
    font-weight: 500;
}