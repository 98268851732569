/* roboto-regular - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/roboto/roboto-v20-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Roboto'), local('Roboto-Regular'),
         url('/fonts/roboto/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/roboto/roboto-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/roboto/roboto-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('/fonts/roboto/roboto-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/roboto/roboto-v20-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-500 - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('/fonts/roboto/roboto-v20-latin-500.eot'); /* IE9 Compat Modes */
    src: local('Roboto Medium'), local('Roboto-Medium'),
         url('/fonts/roboto/roboto-v20-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/roboto/roboto-v20-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/roboto/roboto-v20-latin-500.woff') format('woff'), /* Modern Browsers */
         url('/fonts/roboto/roboto-v20-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/roboto/roboto-v20-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
  }

// @font-face {
//   font-family: 'Basis';
//   src: url('/fonts/BasisGrotesqueMonoPro-Light.woff2') format('woff2'), url('/fonts/BasisGrotesqueMonoPro-Light.woff') format('woff'), url('/fonts/BasisGrotesqueMonoPro-Regular.ttf') format('truetype');
//   font-weight: 300;
//   font-display: swap;
// }
// @font-face {
//   font-family: 'Basis';
//   src: url('/fonts/BasisGrotesqueMonoPro-Regular.woff2') format('woff2'), url('/fonts/BasisGrotesqueMonoPro-Regular.woff') format('woff'), url('/fonts/BasisGrotesqueMonoPro-Regular.ttf') format('truetype');
//   font-weight: 400;
//   font-display: swap;
// }
// @font-face {
//   font-family: 'Basis';
//   src: url('/fonts/BasisGrotesqueMonoPro-Bold.woff2') format('woff2'), url('/fonts/BasisGrotesqueMonoPro-Bold.woff') format('woff'), url('/fonts/BasisGrotesqueMonoPro-Bold.ttf') format('truetype');
//   font-weight: 800;
//   font-display: swap;
// }

h1,
h2,
h3,
h4,
h5 {
    margin: 15px 0;
    font-weight: 300;
    line-height: 1.4;
    &:first-of-type {
        margin-top: 0;
    }
    &:empty {
        margin: 0;
    }
}

h1 {
    font-size: 2.4rem;
    font-weight: 400;
}

h2 {
    font-size: 1.7rem;
}

h3 {
    font-size: 1.4rem;
}

p {
    margin: 15px 0;
    font-size: 1rem;
    line-height: 1.5;
    // font-weight: 200;
    &:first-of-type {
        margin-top: 0;
    }
    &:empty {
        margin: 0;
    }
    span {
        font-size: inherit;
        line-height: inherit;
    }
    strong {
        font-weight: 500;
    }
}

.intro-text {
    font-size: 1.8rem;
    line-height: 1.3;
}

a {
    text-decoration: underline;
    font-weight: 500;
    color: @c-main;
}