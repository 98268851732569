.header__search-container {
    position: relative;
    z-index: 101;
    @media @tablet {
        z-index: auto;
    }
}

.header__search-form {
    display: flex;
    @media @tablet {
        position: fixed;
        top: ~"calc(@{header-height--tablet} - 3px)";
        right: 0;
        left: 0;
        background-color: #FFF;
        // visibility: hidden;
        // opacity: 0;
        z-index: -1;
        pointer-events: none;
        transform: translateY(-110%);
        .transition(.2s);
        &--active {
            // visibility: visible;
            // opacity: 1;
            pointer-events: all;
            transform: translateY(0);
        }
    }
}

.header__search-input {
    border: 1px solid #ddd;
    padding: 8px 12px;
    @media @tablet {
        padding: 15px;
        flex: 1;
    }
}

.header__search-submit {
    border: 0;
    padding: 8px 12px;
    background-color: @c-main;
    color: #fff;
    font-weight: 500;
    margin-left: -1px;
    font-size: .9rem;
    position: relative;
    text-indent: -999999px;
    overflow: hidden;
    height: 40px;
    width: 40px;
    cursor: pointer;
    .transition(.2s);
    &:after {
        content: '\f002';
        font-family: 'fontAwesome';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-indent: 0;
    }
    &:hover {
        background-color: lighten(@c-main, 15%);
    }
    @media @tablet {
        height: auto;
    }
}

.search-trigger {
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    &:after {
        content: '\f002';
        font-family: fontAwesome;
    }
    @media @tablet-min {
        display: none;
    }
}

.search-box {
    background-color: #f5f5f5;
    padding: 30px;
    margin-bottom: 30px;
}

.search-box__title {
    margin-bottom: 20px;
}

.search-box__form {
    display: flex;
    flex-wrap: wrap
}

.search-box__button {
    .button;
    margin: 0 0 0 10px;
    @media @tablet {
        margin: 10px 10px 0 0;
    }
    &--location {
        background-color: @c-secondary;
    }
}

.search-box__input {
    padding: 5px 10px;
    @media @tablet {
        flex: 0 1 100%;
    }
}