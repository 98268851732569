@desktop-1920: ~"only screen and (max-width : 1920px)";
@desktop-extra-wide: ~"only screen and (min-width : 1500px)";
@desktop-medium: ~"only screen and (max-width : 1500px)";
@desktop-small: ~"only screen and (max-width : 1400px)";
@desktop-wide: ~"only screen and (max-width : 1200px)";
@ipad-landscape:  ~"only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape)";
@desktop: ~"only screen and (max-width : 992px)";
@desktop-min:  ~"only screen and (min-width : 993px)";
@tablet: ~"only screen and (max-width : 768px)";
@tablet-min: ~"only screen and (min-width : 769px)";
// @tablet-max: ~"only screen and (max-width : 767px)";

@tablet-desktop: ~"only screen and (min-width: 768px) and (max-width: 992px)";
@mobile-tablet: ~"only screen and (min-width: 480px) and (max-width: 768px)";

@mobile: ~"only screen and (max-width : 480px)";
@mobile-s: ~"only screen and (max-width : 320px)";
@retina: ~"(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)";
@ie: ~"screen and (-ms-high-contrast: active), (-ms-high-contrast: none)";

// colours
@c-body: #666666;
@c-body: @c-blue--main;
@c-body-blue: #2E3092;
@c-main: @c-blue--main;
@c-secondary: @c-blue--light;
@c-warning: #374858;
@c-warning--new: #F3686F;
@c-dark: #2e2e2e;

@c-purple: #5A296C;
@c-blue: #367e96 ;
@c-yellow: #F4ED2F;
@c-grey--dark: #6C6D70;
@c-grey: #D1D2D4;
@c-blue--main: #125862;
@c-blue--light: #3BD9CA;
// height & width values
@container-width: 1200px;
@header-height: 80px;
@header-height--sticky: 90px;
@header-height--tablet: 120px;
@content-padding: 60px;
@layout-padding: 15px;
@panel-height: 200px;
@panel-padding: 20px;

// fonts
@font-sans-serif: 'Roboto', 'Segoe UI', sans-serif;
@font-awesome: "Font Awesome 5 Free";

// Animation
@animation: .7s cubic-bezier(0.57, 0.05, 0.19, 0.99);