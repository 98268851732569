.main-banner {
    background-color: @c-blue--main;
    background-size: cover;
    background-position: 80%;
    color: #FFF;
    position: relative;
    min-height: 327px;
    display: flex;
    align-items: center;
    overflow: hidden;
    // .gradient-strip;
    &:after {
        content: '';
        position: absolute;
        top: 10px;
        right: 0;
        bottom: 0;
        // background-image: url(/img/ernie.jpg);
        width: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: .3;
    }
    a {
        color: #fff;
    }
    &--diagonal{
        min-height: 372px;
        .main-banner__overlay{
            display: none;
           // opacity: .5;
            @media @desktop{
                display: block;
            }
        }
        .main-banner__text{
            @media @mobile{
             //   max-width: 50%; 
            }
                
        }
    }
}
.main-banner__diagonal-img{
    height: 100%;
    object-fit: cover;
}
.main-banner__overlay {
   // display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000;
    opacity: .5;
    z-index: 1;

   
}
.main-banner__container {
    position: relative;
    padding-top: 80px;
    padding-bottom: 80px;
}
.main-banner__image-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 54%;

    &:before {
        content: '';
       // height: 200vh;
       // width: 200vw;
        transform: rotate(30deg);
        background-color: #125862;
        display: block;
        position: absolute;
        /*left: -172%;
        top: -190%;
        left: -323%;
        top: -400%;*/
        top: -34%;
        left: -14%;
        width: 298px;
        height: 513px;
        //background: red;

        /*@media only screen and (max-width: 2100px) {
            left: -350%;
        }

        @media only screen and (max-width: 1950px) {
            left: -325%;
        }

        @media only screen and (max-width: 1950px) {
            left: -339%;
            top: -354%;
        }*/
  
        @media only screen and (max-width: 1385px) {
            left: -26%;
            top: -32%;
        }

        /*@media only screen and (max-width: 1280px) {
            left: -326%;
        }

        @media only screen and (max-width: 1140px) {
            left: -315%;
        }*/
        /*@media @desktop-1920 {
            left: -325%;
        }

        @media @desktop-extra-wide {
            left: -340%;
            top: -404%;
        }



        @media @desktop-medium {
            left: -305%;
            top: -466%;
        }

        @media @desktop-small {
            left: -358%;
            top: -286%;
        }*/
        @media @desktop {
            display: none;
        }
    }

    .main-banner__image {
        height: 100%;
        object-fit: cover;
    }

    @media @desktop {
        width: 100%;
    }
}
.main-banner__content-container {
    position: relative;
    z-index: 2;
    @media @tablet-min {
        max-width: 500px;
    }
}

.main-banner__title {
    font-size: 3rem;
    line-height: 1.2;
    margin: 0;
    text-transform: uppercase;
    @media @tablet{
        font-size: 2rem;
    }
    @media @mobile{
        font-size: 2rem;
    }
}

.main-banner__text {
    font-size: 1.2rem;
    p {
        font-size: inherit;
        margin: 20px 0;

        @media @mobile{
            font-size: 1rem;
        }
    }
}

.main-banner__link {
    color: #fff;
    display: inline-flex;
    align-items: center; 
    font-weight: 500;
    font-size: 1.1rem;
    position: relative;
    text-decoration: none;
    &:hover {
        text-decoration: none;
        &:before {
            transform: scaleX(1);
        }
        &:after {
            transform: scale(.8);
        }
    }
    &:before {
        content: '';
        position: absolute;
        bottom: -5px;
        right: 0;
        left: 0;
        border-bottom: 2px solid @c-yellow;
        transform: scaleX(0);
        transform-origin: left;
        will-change: transform;
        .transition(.2s);
    }
    &:after {
        content: '\f138';
        font-family: 'fontAwesome';
        margin-left: 10px;
        font-size: 1.5rem;
        will-change: transform;
        .transition(.2s);
    }
} 

.main-banner__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    // width: 50%;
    max-width: 700px;
    height: 100%;
    // object-fit: cover;
    z-index: 1;
    opacity: .5;
    @media @tablet {
        display: none;
    }
}