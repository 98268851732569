header {
    z-index: 101;
    position: relative;
    background-color: @c-blue--main;

    @media @tablet {
        position: sticky;
        top: 0;
        border-bottom: 1px solid #eee;
        height: @header-height--tablet;
    }
}

.header__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
 
    padding-top: 20px; 
    padding-bottom: 20px;
    @media @tablet {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.logo {
    display: flex;
    align-items: center;
  //  margin-right: auto;
}

.logo__img {
   height: 100px;
    will-change: transform;
    .transition(.3s);
    @media @tablet-min {
        &:hover {
            transform: scale(.98);
        }
    }
    @media @tablet {
        max-width: 220px;
        height: auto;
    }
    @media @mobile {
        max-width: 190px;
    }
}
.header--sticky {
    position: fixed;
    width: 100%;
    &:before {
        transform: scaleY(1);
        // box-shadow: 0 0 50px 0 rgba(0,0,0,.05);
    }

    .logo {
        transform: scale(0.6) translate(-30%, -30%);
        margin-top: 35px;
    }


    .header__container {
        height: @header-height--sticky;
    }

    @media @tablet{
        height: 90px;
    }
}