.accordion-group {
    counter-reset: accordion-counter;
    position: relative;
}

.accordion {
    margin: 20px 0;
    &--steps {
        counter-increment: accordion-counter;
        margin-left: 40px;
        &:before {
            content: '';
            position: absolute;
            top: 16px;
            left: 14px;
            bottom: 16px;
            border-right: 2px solid #eee;
            z-index: 1;
        }
        .accordion__title {
            &:after {
                content: counter(accordion-counter);
                position: absolute;
                top: 15px;
                left: -40px;
                height: 30px;
                width: 30px;
                border-radius: 50%;
                border: 2px solid #eee;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 500;
                font-size: 1rem;
                background-color: #FFF;
                z-index: 2;
                color: @c-body;
            }
        }
    }
}

.accordion__title {
    position: relative;
    background-color: @c-blue--main;
    margin: 0;
    padding: 13px 20px;
    font-size: 1.3rem;
    cursor: pointer;
    color: #fff;
    user-select: none;
    .transition(.3s);
   
}

.accordion__icon {
    margin-right: 10px;
}

.accordion__content {
    border-top: 0;
    max-height: 0;
    overflow: hidden;
    color: @c-blue--main;
    .transition(.3s);

    &--open {
        border: 2px solid @c-blue--main;
        max-height: 500px;
    }

    p {
        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .panel {
        margin-bottom: 20px;

        &:last-of-type {
            margin: 0;
        }
    }
}

.accordion__padding {
    padding: 20px;
}