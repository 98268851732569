.banner-strip {
    background-color: @c-grey;
    font-weight: 500;
    color: @c-body-blue;
}

.banner-strip__container {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 20px;
    padding-bottom: 20px;
}

.banner-strip__text {
    margin-right: 20px;
    @media @desktop-wide {
        flex: 0 1 100%;
        margin: 0 0 10px;
    }
}

.banner-strip__item {
    margin: 0 20px 0 0;
    position: relative;
    padding-left: 30px;
    @media @desktop-wide {
        margin: 5px 20px 5px 0;
    }
    &:before, &:after {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }
    &:before {
        content: '';
        background-color: #FFF;
        height: 16px;
        width: 16px;
        border-radius: 50%;
    }
    &:after {
        content: '\f058';
        font-family: 'fontAwesome';
        color: @c-secondary;
        font-size: 1.2rem;
    }
}