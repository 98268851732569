.bullet-list__title{
    font-size: 2rem;
    font-weight: 500;
}
.bullet-list__text{

}


.custom-bullet-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    @media @mobile{
        display: block;
    }
    .fa {
       font-size: 1.8rem;
     min-width: 40px;
     
    }
}

.custom-bullet {
   // padding-left: 25px;
    margin-bottom: 15px;
    font-weight: 500;
    flex: 0 0 50%;
    padding-right: 30px;

    display: flex; 

}
.bullet-list__text{
    margin-left: 10px;
    margin-top: 5px;
}