

.button {
    background-color: @c-blue--main;
    color: #FFF;
    border: 0;
    padding: 15px;
    outline: none;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 500;
    position: relative;
    display: inline-block;
    min-width: 120px;
    font-size: .75rem;
    text-align: center;
    letter-spacing: 1px;
    cursor: pointer;
    margin: 0 10px 10px 0;
   // .transition(.3s);

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #FFF;
        pointer-events: none;
        opacity: 0;
      //  .transition(.2s);
    }

    &:hover {
        text-decoration: none;
        color: @c-blue--main;
        background-color: @c-blue--light !important;

        &:after {
            opacity: .1;
        }
    }

    &--secondary {
        background-color: @c-secondary;
        color: @c-blue--main;
    }

    &--ghost {
        background: none;
        border: 2px solid @c-main;
    }

    &--rte {
        padding: 15px;
        background-color: @c-blue--main;
        color: #fff;

        &:hover {
            background: #fff;

            a {
                background: #fff;
                color: @c-blue--main;
            }
        }
        .button--secondary{
            background: @c-blue--main;
            color: #fff;
        }
        &.button--secondary {
            background-color: @c-blue--light;
            color: @c-blue--main;

            a {
                background-color: @c-blue--light;
                color: @c-blue--main;
            }

            &:hover {
                background: @c-blue--main !important;
                color: #fff;

                a {
                    background: @c-blue--main !important;
                    color: #fff;
                }
            }
        }

        a {
           
            text-decoration: none;
            
        }
    }
}
