﻿.carousel {
    
}

.carousel__slide {
    // padding-top: 56.5%;
    position: relative;
}

// .carousel__img {
//     position: absolute;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     object-fit: cover;
// }

.carousel__content {
    position: absolute;
    bottom: 70px;
    background-color: rgba(0,0,0,.7);
    padding: 30px;
    color: #FFF;
    z-index: 10;
    a {
        color: inherit;
    }
    @media @tablet{
        bottom: 0;
    }
    @media @mobile{
        padding: 5px;
    }
}

.owl-dots {
    position: absolute;
    bottom: 30px;
    left: 50%;
    margin-left: -(@container-width / 2) + @layout-padding;
}

.owl-dot {
    height: 10px;
    width: 10px;
    background-color: #FFF;
    border-radius: 50%;
    margin-right: 10px;
    opacity: .5;
    &.active {
        opacity: 1;
    }
}
.carousel__title{
    @media @tablet{
        font-size: 1rem;
    }
    @media @mobile{
        font-size: .9rem;
    }
}
.carousel__text{
    @media @mobile{
        font-size: 1rem;
    }
}