

.row {
  width: auto;
  margin: 0 -@layout-padding;
  display: flex;
  flex-wrap: wrap;
  &:after {
    content: '';
    display: table;
    clear: both;
  }
  &--reverse {
    flex-direction: row-reverse;
  }
}


.container {
  width: 100%;
  max-width: @container-width;
  margin: 0 auto;
  position: relative;
  padding: 0 @layout-padding;
  &--static {
    position: static;
  }
  &--max-width {
    max-width: 800px !important;
  }
  &--fluid {
    max-width: none;
  }
  &--half {
    max-width: @container-width / 2;
    margin: 0;
  }
}

*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

// Target all cols
[class*='col-'] {
  padding: 0 @layout-padding;
  position: relative;
  min-height: 1px;
  // flex: 0 0 auto;
  @media @tablet {
    flex-basis: 100% !important;
    max-width: 100% !important;
  }
}

@cols: 12;

.generateCols(@counter) when (@counter > 0) {
  @width: (@counter / @cols ) * 100;
  .col-md-@{counter} {
      flex-basis: ~"@{width}%";
      max-width: ~"@{width}%";
  }
  .desktop-@{counter} {
      @media @desktop {
        flex-basis: ~"@{width}%";
        max-width: ~"@{width}%";
      }
  }
  .tablet-@{counter} {
    @media @tablet {
      flex-basis: ~"@{width}%";
      max-width: ~"@{width}%";
    }
}
.mobile-@{counter} {
  @media @mobile {
    flex-basis: ~"@{width}%";
    max-width: ~"@{width}%";
  }
}
  .generateCols((@counter - 1));
}

.generateCols(@cols);



// umbraco grid hack
.fullwidth {
  margin: 45px 0;
  width: 100%;

  @media @tablet{
      margin: 20px 0;
  }
}

[class*='col-'] {
  .container {
    padding: 0;
  }
}