.quote-panel {
    background-color: @c-main;
    color: #FFF;
    padding: 80px 30px;
    position: relative;
    margin: 30px 0;
    text-align: center;
    &:first-of-type {
        margin-top: 0;
    }
}

.homePage {
    .quote-panel {
        margin: 0;
    }
} 

.quote-panel__text {
    font-size: 1.8rem;
    line-height: 1.3;
    font-weight: 500;
    max-width: 800px;
    margin: 0 auto;
    p,em {
        font-size: 1.5rem;
        line-height: inherit;
    }
    
}

.quote-panel__sub {
    text-transform: uppercase;
    font-weight: 500;
    font-size: .9rem;
}