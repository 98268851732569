html {
    font-size: 16px;
}

body {
    color: @c-body;
    padding: 0;
    margin: 0;
    font-family: @font-sans-serif;
    font-size: 16px;
    /*&:after {
        content: '';
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0,0,0,.5);
        pointer-events: none;
        visibility: hidden;
        opacity: 0;
        .transition(.3s);
    }*/
}

.body--overlay {
    overflow: hidden;
    /*&:after {
        visibility: visible;
        opacity: 1;
    }*/
}

.wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    // overflow-x: hidden;
    flex: 1;
    // padding-top: @header-height;
}

main {
    flex: 1 0 auto;
    height: 100%;
}

.row-container {
    margin: 45px 0;
    padding: 30px 0;
    color: @c-blue--main;

    @media @tablet{
        margin: 20px 0;
        padding: 20px 0;
    }

    &--dark {
        background-color: @c-blue--main;
        color: #fff;

        .bullet-list {
            color: #fff
        }
    }

    &--light {
        background-color: @c-blue--light;

        .bullet-list {
            color: @c-blue--main;
        }
    }

    &--white {
        background-color: #fff;
        color: @c-blue--main;

        .bullet-list {
            color: @c-blue--main;
        }
    }
}