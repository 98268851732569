.main-content {
    margin: @content-padding 0;
    // &:not(:first-of-type) {
    //     margin-top: 0
    // }
}

.main-content__title {

}

.main-content__image {
    @media @tablet-min {
        float: right;
        margin: 0 0 20px 20px;
    }
    @media @tablet {
        width: 100%;
        margin: 30px 0;
    }
}

img, iframe {
    display: block;
    max-width: 100%;
}

main {
    // a:not(.button):not(.social-icon):not(.panel__link):not(.carousel__link) {
    //     font-weight: 500;
    //     margin: 0 10px 10px 0;
    //     display: inline-block;
    //     &:not(:empty) {
    //         &:before {
    //             content: '\f08e';
    //             font-family: 'fontAwesome';
    //             color: @c-secondary;
    //             margin-right: 10px;
    //         }
    //     }
    // }
}


.news-image{
    margin-bottom:20px;
}
.textImageBox{
    margin: 60px 0;
}