.breadcrumb {
    background: none;
    padding: 0;
    // margin: 25px 0 -45px;
    margin: 25px 0;
    list-style-type: none;
}

.breadcrumb__item {
    margin: 5px 0;
    display: inline-block;
    &:not(:first-of-type) {
        &:before {
            content: '\f054' !important;
            font-family: 'fontAwesome';
            color: @c-main;
            font-weight: 400;
            font-size: .7rem;
            padding: 0 10px;
        }
    }
}

.breadcrumb__link {
    color: @c-main;
    font-weight: 500;
    &:hover {
        color: @c-main;
    }
}