

/*.form-group__row {
    display: flex;
    margin: 0 -10px;
    .form-group {
        flex: 1;
        padding: 0 10px;
    }
}

.form-group {
    position: relative;
    width: 100%;
    margin-bottom: 30px;
}

.form-group__label {
    position: absolute;
    left: 0;
    font-size: .8rem;
    font-weight: 200;
    top: 0;
    will-change: transform;
    .transition(.3s);
}

.form-group__input {
    background: none;
    border: 0;
    outline: none;
    border-bottom: 1px solid #FFF;
    width: 100%;
    color: #FFF;
    font-family: @font-sans-serif;
    padding: 0 0 10px;
    appearance: none;
    border-radius: 0;
    &:focus {
        border-color: @c-main;
        ~.form-group__label {
            transform: translateY(-20px) scale(.8);
        }
    }
    &::placeholder {
        color: #FFF;
        font-size: .7rem;
    }
}


.select-container {
    width: 100%;
    position: relative;
    &:after {
        content: '\f078';
        font-family: 'fontAwesome';
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        font-size: .8rem;
    }
    select {
        appearance: none;
        width: 100%;
        padding-right: 40px;
    }
}

select {
    padding: 10px;
    border: 1px solid #eee;
}

.checkbox-container {
    margin: 0 0 10px;
    text-align: left;
    &:last-of-type {
        margin: 0;
    }
    input[type=checkbox] {
        opacity: 0;
        display: inline;
        position: absolute;
        left: -9999px;
        &:checked {
            & ~ label {
                &:before {
                    background-color: @c-main;
                    border-color: @c-main;
                }
                &:after {
                    transform: scale(1);
                }
            }
        }
    }
    label {
        display: inline-block;
        position: relative;
        padding-left: 35px;
        font-weight: 500;
        font-size: .9rem;
        cursor: pointer;
        &:before, &:after {
            position: absolute;
            display: block;
        }
        &:before {
            content: '';
            height: 20px;
            width: 20px;
            background-color: #FFF;
            border: 1px solid #b9b9b9;
            top: 0;
            left: 0;
            border-radius: 3px;
            .transition(.2s);
        }
        &:after {
            content: '\f00c';
            font-family: 'fontAwesome';
            font-weight: 400;
            font-size: .8rem;
            color: #FFF;
            // width: 12px;
            // height: 12px;
            // background-color: @c-main;
            top: 2px;
            left: 3px;
            transform: scale(0);
            transition: all .2s cubic-bezier(0.18, 0.89, 0.32, 1.28);
            // padding: 1px 0 0 2px;
        }
    }
}

.label--hide {
    position: absolute;
    left: -9999999px;
}*/

.formulate__form {
    font-family: @font-sans-serif;

    label {
        display: none;
    }

    input[type=text] {
        height: 40px;
        margin-bottom: 10px;
       
        width: 100%;
        font-family: @font-sans-serif;
    }

    input, textarea, select {
        margin: 5px 0;
     outline: none;
        padding: 5px;
    }

    select {
        height: 40px;
        width: 100%;
        font-family: @font-sans-serif;
    }

    textarea {
        width: 100%;
        height: 100px;
        font-family: @font-sans-serif;
    }

    button[type=submit] {
        background-color: @c-blue--main;
        color: #FFF;
        border: 0;
        padding: 15px;
        outline: none;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 500;
        position: relative;
        display: inline-block;
        font-family: @font-sans-serif;
        min-width: 120px;
        font-size: .75rem;
        text-align: center;
        letter-spacing: 1px;
        cursor: pointer;
        margin-top: 20px;
    }

    .formulate__field--checkbox {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;

        label {
            display: inline-block;
            margin: 2px 0;
            padding-left: 10px;
        }
    }
}
.formulate__validation-summary{
    display: none;
}
.formulate__inline-validation-summary{
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.formulate__inline-validation-summary__error {
    color: darkred;
    padding: 0;
    margin: 0;
}