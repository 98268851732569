/*.panel--colour {
    .panel__title {
        color: #FFF;
    }
}*/

.panel {
    &--main {
        background-color: @c-blue--main;
        color: #FFF;

        .custom-blockquote__icon {
            background-color: @c-blue--main;
        }

        .button {
            background-color: lighten(@c-blue--main, 10%);
            // background-color: @c-blue--light;
            //color: @c-blue--main;
        }

        .panel__title {
            border-bottom: 3px solid #fff;
           // color: red;
        }
    }

    &--secondary {
        background-color: @c-secondary;
        color: @c-blue--main;

        &.quote-panel {
            &:before, &:after {
                display: none;
            }
        }

        .cta-panel__buttons {
            .button {
                background-color: @c-blue--main;
            }
        }

        .panel__title {
            border-color: #FFF;
        }

        .custom-blockquote__icon {
            background-color: @c-secondary;
        }
        /*.button {
            background-color: lighten(@c-secondary, 10%);
        }*/
        .panel__icon {
            background-color: @c-main;
        }
    }

    &--warning {
        background-color: @c-warning--new;
        color: #06292D;

        &.quote-panel {
            &:before, &:after {
                display: none;
            }
        }

        .panel__title { 
            border-color: #FFF;
        }

        .custom-blockquote__icon {
            background-color: @c-warning--new;
        }

        .button {
            background-color: @c-blue--main;
        }

        .panel__icon {
            background-color: #FFF;
            color: @c-warning--new;
        }
    }
}