.navbar {
   // background-color: #eee;
    z-index: 100;
    border-radius: 0;
    min-height: 0;
    margin: 0;
    min-width: 604px;
    .transition(.3s);
    @media @tablet-min {
        position: sticky;
        top: 0;
    }
    @media @tablet {
        position: fixed;
       // top: ~"calc(@{header-height--tablet} - 1px)";
       top : 0;
        left: 0;
        right: 0;
        background-color: #FFF;
        padding: 20px 0;
        transform: translateY(-100%);
        max-height: ~"calc(100vh - @{header-height--tablet})";
        overflow-y: auto;
        .transition(.4s);
        &--active {
            transform: translateY(0);
            box-shadow: 0 20px 30px -10px rgba(0,0,0,.2);
        }
    }
}

.navbar__container {
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: flex-end;

    @media @tablet{
        display: block;
    }
}

.navbar-nav {
    width: 100%;
    padding: 0;
    list-style-type: none;
    @media @tablet-min {
        display: flex;
        margin: 0 -20px;
    }
    @media @tablet {
        margin: 0;
        padding: 0;
    }
}

.nav__item {
    position: relative;
    &.active {
        .nav__link.dropdown-toggle {
            color: @c-main;
            font-weight: 500;
            &:after {
                transform: scaleX(1);
                border-color: @c-main;
            }
        }
    }
    &-parent {
        &:hover {
            .nav__dropdown {
                transform: translateY(0);
                opacity: 1;
                visibility: visible;
                pointer-events: all;
            }
        }
        @media @tablet-min {
            display: flex !important;
            align-items: stretch;
        }
    }
    &--dropdown {
        border-bottom: 1px solid #eee;
        &:last-of-type {
            border-bottom: 0;
        }
    }
}


.nav__link {
    display: block;
    padding: 0;
    color: #fff;
    font-size: 1.04rem;
    background: none;
    text-decoration: none;
    text-transform: uppercase;
    padding: 20px;
    font-weight: 100;
    &:not(.nav__link--dropdown) {
        &:hover {
            background: none;
            color: darken(#939598, 5%);
        }
    }
    @media @tablet {
        padding: 10px 15px;
        color: @c-body;
    }
    &--dropdown {
        padding: 10px 15px;
        font-size: 1rem;
        display: block;
        color: @c-body;
    }
}

.nav__dropdown {
    position: absolute;
    border: 0;
    padding: 0;
    margin: 0;
    will-change: transform;
    background-color: #FFF;
    list-style-type: none;
    .transition(.3s);
    @media @tablet-min {
        display: block;
        visibility: hidden;
        pointer-events: none;
        opacity: 0;
        transform: translateY(-10px);
        top: 60px;
        left: 0;
        border-radius: 5px;
        box-shadow: 0 10px 20px -10px rgba(0,0,0,.5);
        min-width: 200px;
        &:before {
            content: '';
            height: 15px;
            width: 15px;
            background-color: #FFF;
            transform: rotate(45deg);
            position: absolute;
            top: -5px;
            left: 15px;
            z-index: -1;
        }
    }
    @media @tablet {
        margin: 0 0 0 20px;
        border-left:  2px solid #eee;
        position: relative;
        box-shadow: none;
        border-radius: 0;
        background: none;
    }
}

.menu-trigger {
    background-color: @c-blue--main;
    border: none;
    color: @c-body;
    padding: 5px 10px;
    width: 37px;
    height: 37px;
    position: relative;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin: 0;
    outline: none;
    //border-radius: 5px;
    cursor: pointer;
    //   display: none;
    @media @tablet-min {
        display: none;
    }

    @media @tablet {
        //display: flex;
    }

    &:before, &:after {
        font-family: 'fontAwesome';
        position: absolute;
        .transition(.3s);
        will-change: transform;
        top: 30%;
        right: 30%;
        color: #fff;
        font-size: 1.5rem;
    }

    &:before {
        content: '\f0c9';
    }

    &:after {
        content: '\f00d';
        transform: translateY(-33px);
        color: @c-blue--main;
    }

    &--active {
        background-color: #fff;
        border-color: @c-main;
        color: #FFF;
        z-index: 1000;

        &:before {
            transform: translateY(25px);
        }

        &:after {
            transform: translateY(0);
        }
    }
}
.nav__donate-button{
    padding: 0;
    margin-left: 40px;

    @media @tablet{
        margin-left: 15px;
        margin-top: 10px;
    }
}